.age--page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.age--container {
  width: 70%;
  text-align: center;
}

.age--heading {
  font-size: 3rem;
  font-weight: bolder;
  font-family: cursive;
  margin-bottom: 20px;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.age--input {
  height: 40px;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.age--button {
  height: 40px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.age--button:hover {
  background-color: #45a049;
}

/* Media Query for Tablets and Smaller Desktops */
@media only screen and (max-width: 768px) {
  .age--container {
    width: 80%;
  }
}

/* Media Query for Mobile Devices */
@media only screen and (max-width: 480px) {
  .age--container {
    width: 90%;
  }
}


