.th--container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    animation: fadeInUp 1s ease; /* Add fadeInUp animation */
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .th--head {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
    animation: fadeIn 1s ease; /* Add fadeIn animation */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .home {
    padding: 15px 30px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .home:hover {
    background-color: #45a049;
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* Enhance the box shadow on hover */
  }
  
  .home:focus {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  